import { TRole } from '../pages/auth/interfaces';
import { menuConfigItems } from '../constants/menuConfigItems';

const {
  profile,
  orders,
  users,
  legal,
  settings,
  catalog,
  buyersOrders,
  vendorUsers,
  chats,
} = menuConfigItems;

const getDefaultMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [catalog, profile],
  },
});

const getAdminMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [orders, users, settings, legal, chats],
  },
});

const getVendorMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [orders, vendorUsers],
  },
});

const getBuyerMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [catalog, buyersOrders],
  },
});

const getGuestMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [catalog],
  },
});

const getMenuConfig = (userRoles: TRole[] = ['ROLE_BUYER'], isAuthorized?: boolean) => {
  if (isAuthorized) {
    if (userRoles === undefined) {
      return getDefaultMenu();
    }
    if (userRoles[0] === 'ROLE_ADMIN') {
      return getAdminMenu();
    }
    if (userRoles[0] === 'ROLE_MANAGER') {
      return getDefaultMenu();
    }
    if (userRoles[0] === 'ROLE_VENDOR') {
      return getVendorMenu();
    }
    if (userRoles[0] === 'ROLE_BUYER') {
      return getBuyerMenu();
    }
  } else {
    return getGuestMenu();
  }
};

export default getMenuConfig;
