import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AllPublicationsPage from './AllPublications';
import MyPublicationsPage from './MyPublications';
import CreatePublicationPage from './CreatePublication';
import EditPublicationPage from './EditPublication';
import ViewPublicationPage from './ViewPublication';

export default function BlogPage() {
  return (
    <Switch>
      <Route path="/blog/all" exact component={AllPublicationsPage} />
      <Route path="/blog/my" exact component={MyPublicationsPage} />
      <Route path="/blog/create" exact component={CreatePublicationPage} />
      <Route path="/blog/edit/:id" exact component={EditPublicationPage} />
      <Route path="/blog/view/:id" exact component={ViewPublicationPage} />
    </Switch>
  );
}
