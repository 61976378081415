import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LayoutSplashScreen } from '../../../_metronic';
import { IAppState } from '../../store/rootDuck';
import { actions as authActions } from '../../store/ducks/auth.duck';
import { actions as cartActions } from '../../store/ducks/cart.duck';
import ChatSocket from '../../utils/socket';

const Logout: React.FC<TPropsFromRedux> = ({ logout, hasAuthToken, clearCart }) => {
  useEffect(() => {
    clearCart();
    ChatSocket.closeSocket();
    ChatSocket.clearToken();
    localStorage.removeItem('resVideo');
    logout();
  }, [logout]);

  return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to='/' />;
};

const connector = connect(
  (state: IAppState) => ({
    hasAuthToken: Boolean(state.auth.authToken),
  }),
  {
    logout: authActions.logout,
    clearCart: cartActions.clearCartRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Logout);
