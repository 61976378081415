import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';

import moment from 'moment';
import parse from 'html-react-parser';
import { Button, CardMedia } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { IPublication } from '../../../pages/home/blog/interfaces';
import { API_DOMAIN } from '../../../constants';
import { useFormatMessage } from '../../../hooks';

interface IProps {
  publication: IPublication;
  deletePost: (id: number) => void;
  isAuthor: boolean;
  isAdmin: boolean;
  isView: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      transition: '.2s ease-out',
      '&:hover': {
        transform: 'scale(1.03)',
      },
    },
    rootView: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '80%',
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    media: {
      height: 0,
      paddingTop: '52.25%', // 16:9
      marginBottom: 16,
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
      cursor: 'pointer',
    },
    buttonViewContain: {
      paddingBottom: '15px',
    },
  })
);

export default function PublicationPost({
  publication,
  isAuthor,
  deletePost,
  isAdmin,
  isView = false,
}: IProps) {
  const classes = useStyles();
  const history = useHistory();
  const fm = useFormatMessage();

  // eslint-disable-next-line no-nested-ternary
  const avatar = publication.company
    ? publication.company.logo
    : typeof publication.user.avatar === 'string'
    ? publication.user.avatar
    : publication.user.avatar && publication.user.avatar.small;
  const headerTitle = publication.company
    ? publication.company.name || 'No name'
    : publication.user.fio || 'No name';

  return (
    <Card className={isView ? classes.rootView : classes.root}>
      <div className={isView ? classes.header : ''}>
        <CardHeader
          avatar={
            <Avatar
              aria-label='recipe'
              variant='circle'
              className={classes.avatar}
              src={avatar ? `${API_DOMAIN}/${avatar}` : undefined}
              onClick={() => {
                if (publication.user) history.push(`/users/view/${publication.user.id}`);
                else history.push(`/companies/view/${publication.company.id}`);
              }}
            />
          }
          title={headerTitle}
          subheader={moment(publication.createdAt).format('MMMM D, YYYY')}
        />

        {isView ? (
          <Button
            style={{ margin: '0 16px', padding: '4px 16px' }}
            variant='outlined'
            color='primary'
            onClick={() => history.goBack()}
          >
            {fm('AUTH.BUTTON.BACK')}
          </Button>
        ) : null}
      </div>
      {publication.avatar && (
        <CardMedia
          style={{ cursor: isView ? 'default' : 'pointer' }}
          className={classes.media}
          image={`${API_DOMAIN}/${publication.avatar}`}
          onClick={() => history.push(`/blog/view/${publication.id}`)}
        />
      )}
      <CardContent style={{ paddingTop: 0 }}>
        {!isView ? (
          <Typography
            style={{ fontWeight: 800, cursor: isView ? 'default' : 'pointer' }}
            gutterBottom
            variant='h5'
            component='h5'
            onClick={() => history.push(`/blog/view/${publication.id}`)}
          >
            {publication.title}
          </Typography>
        ) : (
          <Typography
            style={{ fontWeight: 800, cursor: isView ? 'default' : 'pointer' }}
            gutterBottom
            variant='h2'
            component='h2'
            onClick={() => history.push(`/blog/view/${publication.id}`)}
          >
            {publication.title}
          </Typography>
        )}

        {!isView ? (
          <>
            {publication.description && (
              <Typography style={{ color: '#000' }} variant='body2' component='p'>
                {publication.description}
              </Typography>
            )}
          </>
        ) : (
          <>
            {publication.text && (
              <Typography style={{ color: '#000' }} variant='body1' component='div'>
                {parse(publication.text)}
              </Typography>
            )}
          </>
        )}
      </CardContent>

      <CardActions
        style={{ justifyContent: isAuthor || isAdmin ? 'space-between' : 'flex-end' }}
        className={isView ? classes.buttonViewContain : ''}
      >
        <div>
          {(isAuthor || isAdmin) && (
            <>
              <Button
                onClick={() => history.push(`/blog/edit/${publication.id}`)}
                color='primary'
              >
                {fm('edit')}
              </Button>

              <Button
                onClick={() => deletePost(publication.id)}
                color='secondary'
                style={{ marginLeft: 8 }}
              >
                {fm('COMMON.BUTTON.DELETE')}
              </Button>
            </>
          )}
        </div>
        {!isView ? (
          <Button onClick={() => history.push(`/blog/view/${publication.id}`)} color='primary'>
            {fm('read_more')}
          </Button>
        ) : null}
      </CardActions>
    </Card>
  );
}
