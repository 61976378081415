import React from 'react';
import { Button, createMuiTheme, ThemeProvider } from '@material-ui/core';

interface IColorButton {
  color: string;
  darkColor?: string;
  onClick?: () => {} | void;
  title?: string;
  disable?: boolean;
}

const ColorButton: React.FC<IColorButton> = ({ children, color, onClick, disable }) => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: color,
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Button
        onClick={onClick}
        color="primary"
        variant="contained"
        disableElevation
        disabled={disable}
      >
        {children}
      </Button>
    </ThemeProvider>
  );
};

export default ColorButton;
