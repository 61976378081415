import React, { ReactElement, useState, useEffect, useCallback, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import AlertDialog from '../../../components/ui/Dialog/AlertDialog';
import Preloader from '../../../components/ui/Preloader/Preloader';
import { PublicationPost } from '../../../components/ui/Blog';
import useCrudSnackbar from '../../../hooks/useCrudSnackbar';
import { IAppState } from '../../../store/rootDuck';
import { actions as blogActions } from '../../../store/ducks/blog.duck';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { useDefineUserRole } from '../../../hooks/useDefineUserRole';

const ViewPublication: React.FC<TPropsFromRedux & RouteComponentProps<{ id: string }>> = ({
  match: {
    params: { id },
  },
  me,

  publication,
  publicationLoading,

  deleteSuccess,
  deleteError,

  clearMe,
  fetchMe,

  clearFetchPublication,
  fetchPublication,

  clearDeletePublication,
  deletePublication,
}): ReactElement => {
  const intl = useIntl();
  const history = useHistory();

  const [deleteId, setDeleteId] = useState<number | undefined>(-1);
  const [isAlertOpen, setAlertOpen] = useState(false);

  const companyId = me?.company?.id || undefined;
  const isAdmin = useDefineUserRole(me, 'ROLE_ADMIN');

  const handleDeleteDialog = useCallback((id: number | undefined) => {
    setDeleteId(id);
    setAlertOpen(true);
  }, []);

  useCrudSnackbar({
    success: deleteSuccess,
    error: deleteError,
    clear: clearDeletePublication,
    successMessage: `${intl.formatMessage({ id: 'SUCCESS' })}`,
    errorMessage: `${intl.formatMessage({ id: 'PUBLICATIONS.DELETE.ERROR' })}: ${deleteError}`,
    afterSuccess: () => history.push('/blog/all'),
    afterSuccessOrError: () => {
      setAlertOpen(false);
    },
  });

  useEffect(() => {
    fetchMe();
    return () => {
      clearMe();
    };
  }, []);

  useEffect(() => {
    if (id) fetchPublication({ id: +id });
    return () => {
      clearFetchPublication();
    };
  }, [id]);

  const isAuthor = useMemo(() => {
    if (companyId && publication?.company?.id) {
      return companyId === publication.company.id;
    }
    if (me && publication?.user?.id) {
      return me.id === publication.user.id;
    }
    return false;
  }, [publication, companyId, me]);

  if (!publication || publicationLoading) return <Preloader />;

  return (
    <>
      <AlertDialog
        open={isAlertOpen}
        message={intl.formatMessage({ id: 'PUBLICATIONS.DELETE.TEXT' })}
        okText={intl.formatMessage({ id: 'PUBLICATIONS.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'PUBLICATIONS.DELETE.CANCEL' })}
        handleClose={() => {
          setAlertOpen(false);
        }}
        handleAgree={() => deleteId && deletePublication({ id: deleteId })}
      />

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <PublicationPost
          isAuthor={isAuthor}
          isView
          deletePost={handleDeleteDialog}
          key={publication.id.toString()}
          publication={publication}
          isAdmin={Boolean(isAdmin)}
        />
      </div>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    me: state.profile.me,
    loading: state.blog.editPublicationLoading,
    success: state.blog.editPublicationSuccess,
    error: state.blog.editPublicationError,

    publication: state.blog.publication,
    publicationLoading: state.blog.fetchPublicationLoading,
    publicationSuccess: state.blog.fetchPublicationSuccess,
    publicationError: state.blog.fetchPublicationError,

    deleteLoading: state.blog.deletePublicationLoading,
    deleteSuccess: state.blog.deletePublicationSuccess,
    deleteError: state.blog.deletePublicationError,
  }),
  {
    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,

    fetchPublication: blogActions.fetchEditPublicationRequest,
    clearFetchPublication: blogActions.clearFetchEditPublication,

    clearDeletePublication: blogActions.clearDeletePublication,
    deletePublication: blogActions.deletePublicationRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ViewPublication);
