/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { useFormatMessage } from '../../../../app/hooks';

function BreadCrumbs(props) {
  const fm = useFormatMessage();
  const { items, hideBreadcrumbIcon } = props;
  if (!items || !items.length) {
    return '';
  }

  const length = items.length;
  return (
    <div className='kt-subheader__breadcrumbs'>
      {items.map((item, index) => (
        <React.Fragment key={`bc${index}`}>
          <Link
            style={{ fontSize: '1.2rem', fontWeight: 600 }}
            className='kt-subheader__breadcrumbs-link'
            to={`/${item.page}`}
            onClick={() => item.click && item.click()}
          >
            {item.translate ? fm(item.translate) : item.title}
          </Link>
          {length !== index + 1 && <span className='kt-subheader__breadcrumbs-separator' />}
        </React.Fragment>
      ))}
      <span className='kt-subheader__breadcrumbs-separator' />
    </div>
  );
}

export default BreadCrumbs;
