import {createStyles, makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    actions: {
      marginTop: theme.spacing(3),
    },
    cancelBtn: {
      marginRight: theme.spacing(2),
    },
  })
);
