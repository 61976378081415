/* eslint-disable no-plusplus, prefer-const */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Button, ButtonGroup, Card } from '@material-ui/core';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { Formik } from 'formik';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { IAppState } from '../../../store/rootDuck';
import { setLayoutSubheader } from '../../../utils/layout';
import { actions as productActions } from '../../../store/ducks/product.duck';
import Preloader from '../../../components/ui/Preloader/Preloader';
import { actions as cartTypesActions } from '../../../store/ducks/cart.duck';
import { UserRoles } from '../../../interfaces/user';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { IProduct } from '../../../interfaces/product';
import { maskPhone, cleanPhone, thousands } from '../../../utils/utils';
import RichEditor from '../../../components/formComponents/RichEditor';
import { useStylesProducView } from './hooks/useStylesProductView';
import { ButtonWithLoader } from '../../../components/ui/Buttons';
import AlertDialog from '../../../components/ui/Dialog/AlertDialog';
import { useFormatMessage } from '../../../hooks';

const ProductView: React.FC<RouteComponentProps<{ id: string }> & TPropsFromRedux> = ({
  match: {
    params: { id },
  },
  product,
  loading,
  isAuthorized,
  guestCart,
  cart,
  isAdmin,
  me,
  loadingMe,
  editLoading,
  cartCompanyId,
  companyGuestCart,
  countProductsGuest,
  addProductLoading,
  fetch,
  clearProduct,
  setCountGuestCart,
  setProductCount,
  setProductCountLoading,
  fetchMe,
  clearMe,
  addProductToCart,
  setProductGuestCart,
}) => {
  const intl = useIntl();
  const classes = useStylesProducView();
  const fm = useFormatMessage();

  const [isCartAlertOpen, setCartAlertOpen] = useState<boolean>(false);

  setLayoutSubheader({
    title: product?.name || '',
    breadcrumb: [
      {
        title: fm('MENU.PRODUCTS.CATALOG'),
        root: true,
        page: 'products/catalog',
        translate: 'MENU.PRODUCTS.CATALOG',
      },
    ],
  });

  useEffect(() => {
    fetch(Number(id));
    return () => {
      clearProduct();
    };
  }, [id]);

  const descriptionHtml = useMemo(() => {
    if (product?.description) {
      return EditorState.createWithContent(stateFromHTML(product.description));
    }
    return EditorState.createEmpty();
  }, [product]);

  // handle add to cart
  const [cartProductId, setCartProductId] = useState<IProduct | null>(null);

  useEffect(() => {
    fetchMe();
    return () => {
      clearMe();
    };
  }, []);

  const characteristics = useMemo(() => {
    if (product) {
      const result: { [key: string]: any } = {
        [intl.formatMessage({ id: 'PRODUCT.VIEW.CATEGORY' })]:
          product.category?.name || intl.formatMessage({ id: 'COMMON.NO_DATA' }),
      };

      product.parameter_values?.forEach(item => {
        if (item.parameter_id && item.parameter_name) {
          // запрос не возвращает название параметра
          result[item.parameter_name] = item.value;
        }
      });
      return result;
    }
    return {};
  }, [product]);

  const isCompanyInfo = useMemo(() => {
    return (
      product?.company?.brand ||
      // product.company?.address ||
      product?.company?.phone_number ||
      product?.company?.site
      // || product.company?.working_hours
    );
  }, [product?.company]);

  const companyInfo: { [key: string]: any } | null = isCompanyInfo
    ? {
        [intl.formatMessage({ id: 'PRODUCT.VIEW.COMPANY' })]: product?.company?.brand || null,
        // Адрес: product.company?.address || null,
        [intl.formatMessage({ id: 'PRODUCT.VIEW.PHONE_NUMBER' })]:
          maskPhone(product?.company?.phone_number) || null,
        [intl.formatMessage({ id: 'PRODUCT.VIEW.SITE' })]: product?.company?.site || null,
        // 'Часы работы': product.company?.working_hours
        //   ? product.company?.working_hours.replace(/(\r\n|\n|\r)/gm, '<br>')
        //   : null,
      }
    : null;

  let productCount = useMemo(() => {
    if (!isAuthorized && product && guestCart) {
      const item = guestCart.items.find(item => item.product.id === product.id);
      if (item) {
        return item.count;
      }
    } else if (Boolean(isAuthorized) && product && cart) {
      const item = cart.items.find(item => item.product.id === product.id);
      if (item) {
        return item.count;
      }
    }
    return null;
  }, [isAuthorized, product, guestCart?.goods_num, cart?.goods_num, guestCart, cart]);

  const isVendor = useMemo(() => me && me.roles.includes(UserRoles.ROLE_VENDOR), [me]);

  const handleCartDialog = useCallback(
    item => {
      if (isAuthorized) {
        const newCart = cartCompanyId ? item?.company?.id !== cartCompanyId : false;
        if (!newCart) {
          addProductToCart({
            product_id: item.id ? item?.id : 0,
            count: 1,
          });
        } else {
          setCartProductId(item);
          setCartAlertOpen(true);
        }
      } else {
        const newCart = companyGuestCart ? item?.company?.id !== companyGuestCart : false;
        if (!newCart) {
          setProductGuestCart({ data: item, type: 'cart' });
        } else {
          if (countProductsGuest === 0) {
            setProductGuestCart({ data: item, type: 'new' });
          } else {
            setCartProductId(item);
            setCartAlertOpen(true);
          }
          console.log('todo');
        }
      }
    },
    [addProductToCart, cartCompanyId, companyGuestCart, setProductGuestCart]
  );

  const addCartProductAction = useCallback(() => {
    if (!addProductLoading && cartProductId) {
      addProductToCart({
        product_id: cartProductId.id!,
        count: 1,
        newCart: true,
      });
      setCartAlertOpen(false);
    }
  }, [cartProductId, addProductToCart]);

  const addProductGuestCart = useCallback(() => {
    if (cartProductId) {
      setProductGuestCart({ data: cartProductId, type: 'new' });
      setCartAlertOpen(false);
    }
  }, [cartProductId, setProductGuestCart]);

  if (loading || loadingMe || !product) return <Preloader />;

  return (
    <>
      <AlertDialog
        open={isCartAlertOpen}
        message={intl.formatMessage({ id: 'CART.ALERT.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          setCartAlertOpen(false);
        }}
        handleAgree={() => (isAuthorized ? addCartProductAction() : addProductGuestCart())}
      />

      <div className={classes.container}>
        <Card className={classes.card}>
          <div className={classes.leftCol}>
            {/* <ImageGallery product={product} /> */}

            <div className={classes.header}>
              <div>
                <p className={classes.name}>
                  <b>{product.name}</b>
                </p>

                <p className={classes.price}>{thousands(product.price.toString())} ₽</p>
              </div>

              <Formik
                initialValues={{ test: 'test' }}
                onSubmit={() => handleCartDialog(product)}
              >
                {({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    {!productCount
                      ? !isAdmin &&
                        !isVendor && (
                          <ButtonWithLoader disabled={editLoading} loading={editLoading}>
                            {intl.formatMessage({ id: 'PRODUCT.BUTTON.ADD_CART' })}
                          </ButtonWithLoader>
                        )
                      : product &&
                        Boolean(productCount) && (
                          <ButtonGroup
                            color='primary'
                            aria-label='outlined primary button group'
                            variant='contained'
                          >
                            <Button
                              disabled={setProductCountLoading}
                              onClick={() =>
                                isAuthorized
                                  ? setProductCount({
                                      product_id: product.id || 0,
                                      count: --productCount!,
                                    })
                                  : setCountGuestCart({
                                      product_id: product.id || 0,
                                      count: --productCount!,
                                      type: 'dec',
                                    })
                              }
                            >
                              <RemoveIcon fontSize='small' />
                            </Button>
                            <Button
                              style={{
                                pointerEvents: 'none',
                              }}
                            >
                              {productCount}
                            </Button>
                            <Button
                              disabled={setProductCountLoading}
                              onClick={() =>
                                isAuthorized
                                  ? setProductCount({
                                      product_id: product.id || 0,
                                      count: ++productCount!,
                                    })
                                  : setCountGuestCart({
                                      product_id: product.id || 0,
                                      count: ++productCount!,
                                      type: 'inc',
                                    })
                              }
                            >
                              <AddIcon fontSize='small' />
                            </Button>
                          </ButtonGroup>
                        )}
                  </form>
                )}
              </Formik>
            </div>

            {!!product.description && (
              <RichEditor
                className={classes.editor}
                disabled
                editorState={descriptionHtml}
                placeholder=''
                setEditorState={() => {}}
                style={{ padding: 0 }}
              />
            )}
          </div>

          <div className={classes.rightCol}>
            {Object.keys(characteristics).map(key => (
              <>
                {characteristics[key] && (
                  <div key={key} className={classes.listItem}>
                    <p className={classes.listValue}>{characteristics[key]}</p>
                    <p className={classes.listKey}>{key}</p>
                  </div>
                )}
              </>
            ))}

            {companyInfo && (
              <div className={classes.companyCard}>
                {Object.keys(companyInfo).map(key => (
                  <>
                    {companyInfo[key] && (
                      <div key={key} className={classes.listItem}>
                        <p
                          className={classes.listValue}
                          dangerouslySetInnerHTML={{ __html: companyInfo[key] }}
                        />
                        <p className={classes.listKey}>{key}</p>
                      </div>
                    )}
                  </>
                ))}
              </div>
            )}

            {product.company && product.company.phone_number && (
              <a
                rel='noreferrer'
                target='_blank'
                href={`https://wa.me/${cleanPhone(product.company.phone_number)}`}
                className={classes.whatsAppButton}
              >
                {intl.formatMessage({ id: 'PRODUCT.VIEW.WHATSAPP' })}
              </a>
            )}

            {/* <div style={{ marginTop: 30 }}>
              <ReviewsList productId={id} meAdd={meAdd} />
            </div> */}
          </div>
        </Card>
      </div>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    product: state.products.product,
    loading: state.products.byIdLoading,
    meAdd: state.products.reviewsProduct?.meAdd || false,
    isAuthorized: state.auth.user != null,
    guestCart: state.cart.guestCart,
    cart: state.cart.cart,
    setProductCountLoading: state.cart.setProductCountLoading,
    isAdmin: state.auth.user?.is_admin,
    me: state.profile.me,
    loadingMe: state.profile.loading,
    editLoading: state.products.editLoading,
    cartCompanyId: state.cart.companyId,
    companyGuestCart: state.cart.guestCart?.cartCompanyId,
    countProductsGuest: state.cart.guestCart?.items.length,
    addProductLoading: state.cart.addProductLoading,
  }),
  {
    fetch: productActions.fetchByIdRequest,
    clearProduct: productActions.clearProduct,
    setProductGuestCart: cartTypesActions.setProductGuestCart,
    setCountGuestCart: cartTypesActions.setCountGuestCart,
    setProductCount: cartTypesActions.setProductCountRequest,
    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,
    addProductToCart: cartTypesActions.addProductRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProductView);
