import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useCallback } from 'react';
import { stateToHTML } from 'draft-js-export-html';
import { ICreatePublication } from '../../interfaces';
import { useFormatMessage } from '../../../../../hooks';

const getValidationScheme = () => {
  const fm = useFormatMessage();

  return Yup.object().shape({
    title: Yup.string().required(fm('AUTH.VALIDATION.REQUIRED_FIELD')),
  });
};

const getInitValue = () => {
  return {
    title: '',
    description: '',
    text: '',
  };
};

export const useCustomFormik = ({
  submit,
  editorState,
  images,
}: {
  submit: (props: { data: FormData }) => void;
  editorState: any;
  images: File[];
}) => {
  const submitHandler = useCallback(
    (values: ICreatePublication) => {
      const data = new FormData();
      data.append('title', values.title);
      data.append('description', values.description);

      let text = '';
      const editorContent = editorState.getCurrentContent();
      if (editorContent.hasText()) {
        text = stateToHTML(editorContent);
      }
      values.text = text;
      data.append('text', text);

      if (images.length > 0) {
        data.append('photo', images[0]);
      }

      submit({ data });
    },
    [submit, editorState, images]
  );

  return useFormik({
    enableReinitialize: true,
    initialValues: getInitValue(),
    validationSchema: getValidationScheme(),
    onSubmit: submitHandler,
  });
};
