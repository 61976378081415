import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core';
import { Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import { IAppState } from '../../../store/rootDuck';
import { setLayoutSubheader } from '../../../utils/layout';
import { actions as blogActions } from '../../../store/ducks/blog.duck';
import Preloader from '../../../components/ui/Preloader/Preloader';
import { PublicationsList } from './components';
import TablePaginator from '../../../components/TablePaginator/TablePaginator';

import useCrudSnackbar from '../../../hooks/useCrudSnackbar';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import AlertDialog from '../../../components/ui/Dialog/AlertDialog';
import { UserRoles } from '../../../interfaces/user';
import { IPublication } from './interfaces';
import { useFormatMessage } from '../../../hooks';

export const useStyles = makeStyles(
  createStyles({
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    addBtn: {
      marginBottom: 10,
    },
  })
);

const AllPublicationsPage: React.FC<TPropsFromRedux> = ({
  me,
  loadingMe,
  fetchMe,
  clearMe,

  page,
  perPage,
  total,

  loading,
  publications,
  getPublications,
  clearPublications,

  deleteLoading,
  deleteSuccess,
  deleteError,
  deletePublication,
  clearDeletePublication,
}) => {
  const intl = useIntl();
  const fm = useFormatMessage();

  const [deleteId, setDeleteId] = useState<number | undefined>(-1);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [width, setWidth] = useState<number | undefined>();
  const handleDeleteDialog = useCallback((id: number | undefined) => {
    setDeleteId(id);
    setAlertOpen(true);
  }, []);

  const [currentPublicatoins, setCurrentPublications] = useState<IPublication[] | null>(null);
  useEffect(() => {
    setCurrentPublications(publications);
  }, [publications]);

  setLayoutSubheader({ title: fm('all_publications') });

  useEffect(() => {
    fetchMe();
    getPublications({ page: 1, perPage: 20 });
    return () => {
      clearMe();
      clearPublications();
    };
  }, []);

  const isAdmin = useMemo(() => me?.roles.includes(UserRoles.ROLE_ADMIN), [me]);

  useCrudSnackbar({
    success: deleteSuccess,
    error: deleteError,
    clear: clearDeletePublication,
    successMessage: `${intl.formatMessage({ id: 'SUCCESS' })}`,
    errorMessage: `${intl.formatMessage({ id: 'PUBLICATIONS.DELETE.ERROR' })}: ${deleteError}`,
    afterSuccess: () => {
      getPublications({ page, perPage });
    },
    afterSuccessOrError: () => {
      setAlertOpen(false);
    },
  });

  const updateWidth = () => setWidth(window.innerWidth);

  useEffect(() => {
    updateWidth();
  }, [updateWidth]);

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
  }, []);

  if (loading || deleteLoading || loadingMe || !currentPublicatoins) return <Preloader />;

  return (
    <>
      <AlertDialog
        open={isAlertOpen}
        message={intl.formatMessage({ id: 'PUBLICATIONS.DELETE.TEXT' })}
        okText={intl.formatMessage({ id: 'PUBLICATIONS.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'PUBLICATIONS.DELETE.CANCEL' })}
        handleClose={() => {
          setAlertOpen(false);
        }}
        handleAgree={() => deleteId && deletePublication({ id: deleteId })}
      />

      <Row>
        <Col>
          <PublicationsList
            deletePost={handleDeleteDialog}
            meId={me?.id || 0}
            companyId={me?.company?.id || undefined}
            publications={currentPublicatoins}
            isAdmin={isAdmin}
            width={width}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'stretch',
            }}
          >
            <TablePaginator
              page={page}
              realPerPage={currentPublicatoins.length}
              perPage={perPage}
              total={total}
              fetchRows={getPublications}
              label={intl.formatMessage({ id: 'PUBLICATIONS.TABLE.PERPAGE' })}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    me: state.profile.me,
    loadingMe: state.profile.loading,

    page: state.blog.page,
    perPage: state.blog.per_page,
    total: state.blog.total,

    loading: state.blog.publicationsLoading,
    success: state.blog.publicationsSuccess,
    error: state.blog.publicationsError,
    publications: state.blog.publications,

    deleteLoading: state.blog.deletePublicationLoading,
    deleteSuccess: state.blog.deletePublicationSuccess,
    deleteError: state.blog.deletePublicationError,
  }),
  {
    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,

    getPublications: blogActions.publicationsRequest,
    clearPublications: blogActions.clearFetchPublications,

    deletePublication: blogActions.deletePublicationRequest,
    clearDeletePublication: blogActions.clearDeletePublication,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AllPublicationsPage);
