import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeAxiosPostRequest } from '../../../../../utils/utils';
import { IPublication } from '../../interfaces';
import { actions } from '../../../../../store/ducks/blog.duck';

export const useUploadPublicationImage = (publicationId: IPublication['id'] | undefined) => {
  const dispatch = useDispatch();
  const [res, setRes] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<any>(null);

  const uploadImage = useCallback(
    (files: File[]) => {
      const url = `/api/publication/${publicationId}/upload_logo`;
      const formData = new FormData();
      files.forEach(file => formData.append('photo', file));
      makeAxiosPostRequest(url, formData, setRes, setLoading, setErr, {
        headers: { 'content-type': 'multipart/form-data' },
      });
    },
    [publicationId]
  );

  useEffect(() => {
    if (res) dispatch(actions.fetchEditPublicationSuccess(res.data));
  }, [res]);

  // cleanup
  useEffect(() => {
    setRes(null);
    setErr(null);
  }, [res, err]);

  return [err, uploadImage, loading];
};
