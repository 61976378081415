import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useCallback } from 'react';
import { stateToHTML } from 'draft-js-export-html';
import { IEditPublication, IPublication } from '../../interfaces';
import { useFormatMessage } from '../../../../../hooks';

const getValidationScheme = () => {
  const fm = useFormatMessage();

  return Yup.object().shape({
    title: Yup.string().required(fm('AUTH.VALIDATION.REQUIRED_FIELD')),
  });
};

const getInitValue = (data: Partial<IPublication> | null) => {
  return {
    title: data?.title || '',
    description: data?.description || '',
    text: data?.text || '',
  };
};

export const useCustomFormik = ({
  data,
  id,
  submit,
  editorState,
}: {
  data: Partial<IPublication> | null;
  id: string;
  submit: (props: { id: number; data: IEditPublication }) => void;
  editorState: any;
}) => {
  const submitHandler = useCallback(
    (values: Partial<IPublication>) => {
      let text = '';
      const editorContent = editorState.getCurrentContent();
      if (editorContent.hasText()) {
        text = stateToHTML(editorContent);
      }
      values.text = text;
      submit({ id: +id, data: values });
    },
    [id, submit, editorState]
  );

  return useFormik({
    enableReinitialize: true,
    initialValues: getInitValue(data),
    validationSchema: getValidationScheme(),
    onSubmit: submitHandler,
  });
};
