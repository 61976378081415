import React from 'react';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { IPublication } from '../interfaces';
import { PublicationPost } from '../../../../components/ui/Blog';

interface IProps {
  publications: IPublication[];
  deletePost: (id: number) => void;
  meId: number;
  companyId?: number;
  isAdmin?: boolean;
  width?: number | undefined;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    publicationContainer: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'row',
      // flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    publicationColumn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',

      [theme.breakpoints.up('sm')]: {
        width: '49%',
      },
    },
    publicationItem: {
      marginBottom: 15,
      width: '100%',

      [theme.breakpoints.up('sm')]: {
        width: '95%',
      },
    },
  })
);

const PublicationsList: React.FC<IProps> = ({
  publications,
  deletePost,
  meId,
  companyId,
  isAdmin,
  width,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const firstHalf: IPublication[] = [];
  const secondHalf: IPublication[] = [];
  const thirdHalf: IPublication[] = [];

  publications.forEach((publication, index) => {
    const numberInArray = index + 1;

    if (width && width >= 1150) {
      if (numberInArray % 3 === 0) {
        thirdHalf.push(publication);
      } else if (numberInArray % 2 === 0) {
        secondHalf.push(publication);
      } else {
        firstHalf.push(publication);
      }
    } else if (width && width > 600) {
      if (numberInArray % 2 === 0) {
        secondHalf.push(publication);
      } else {
        firstHalf.push(publication);
      }
    } else {
      firstHalf.push(publication);
    }
  });

  return (
    <>
      {publications.length === 0 ? (
        <Typography className={classes.empty} component='h5' variant='h5'>
          {intl.formatMessage({ id: 'COMMON.LIST.EMPTY' })}
        </Typography>
      ) : (
        <div className={classes.publicationContainer}>
          <div className={classes.publicationColumn}>
            {firstHalf.map(publication => (
              <div className={classes.publicationItem}>
                <PublicationPost
                  isView={false}
                  isAuthor={
                    publication.company && companyId
                      ? companyId === publication.company.id
                      : meId === publication?.user?.id || false
                  }
                  deletePost={deletePost}
                  key={publication.id.toString()}
                  publication={publication}
                  isAdmin={Boolean(isAdmin)}
                />
              </div>
            ))}
          </div>

          {width && width > 600 ? (
            <div className={classes.publicationColumn}>
              {secondHalf.map(publication => (
                <div className={classes.publicationItem}>
                  <PublicationPost
                    isView={false}
                    isAuthor={
                      publication.company && companyId
                        ? companyId === publication.company.id
                        : meId === publication?.user?.id || false
                    }
                    deletePost={deletePost}
                    key={publication.id.toString()}
                    publication={publication}
                    isAdmin={Boolean(isAdmin)}
                  />
                </div>
              ))}
            </div>
          ) : null}

          {width && width >= 1150 ? (
            <div className={classes.publicationColumn}>
              {thirdHalf.map(publication => (
                <div className={classes.publicationItem}>
                  <PublicationPost
                    isView={false}
                    isAuthor={
                      publication.company && companyId
                        ? companyId === publication.company.id
                        : meId === publication.user.id || false
                    }
                    deletePost={deletePost}
                    key={publication.id.toString()}
                    publication={publication}
                    isAdmin={Boolean(isAdmin)}
                  />
                </div>
              ))}
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default PublicationsList;
